﻿#courselist {
    .btn-course {
        background: none;
        color: $secondary;
        border-color: $secondary;
        font-weight: 600;
    }
}

#results {
    li {
        background: #fff;
        border-bottom: 1px solid $secondary;

        &:nth-child(odd) {
            background: $light-background;
        }

        a {
            color: $black;
        }
    }
}
