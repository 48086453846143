﻿#topimage {
    position: relative;
    width: 100%;
    z-index: 0;

    .content {
        position: absolute;
        bottom: 100px;
        left: 0px;
        right: 0px;
        margin: auto;
    }

    .content-inner {
        background: #1d1d1b;
        color: #fff;
        padding: 15px;
        display: inline-block;
    }

    h1 {
        font-size: 1.7rem;
    }

    .date {
        font-weight: 600;
    }
}
