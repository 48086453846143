﻿.btn {
    color: #ffffff;
    padding: 10px 25px;
    position: relative;
    z-index: 5;
    border: 3px solid $primary;
    font-weight: 800;

    &.btn-secondary {
        border-color: $secondary;
    }
}

.btn-link {
    font-size: 1rem;
    color: $secondary;
    background: none;
    border: 0px;
    padding: 10px 25px;
    text-decoration: underline;
}

.btn-lg {
    letter-spacing: .15rem;
    padding: 20px 35px;
}

@include media-breakpoint-down(md) {
    .btn, .btn-link {
        padding: 10px 15px;
    }
}
