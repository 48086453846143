﻿header {
    width: 100% !important;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 50;
    transition: all 0.4s ease;
    background: #fff;

    &.fixed {
        background: rgba(255,255,255,1);
        transition: all 0.4s ease;

        #top {
            border: none;
            transition: all 0.4s ease;
        }
    }
}

#topbanner{
    background:$primary;
    color:#fff;
    padding:5px 0;
    height:35px;
    strong{
        text-transform:uppercase;
        margin-right:10px;
    }
}

#top {
    border-bottom: 1px solid rgba(236, 236, 238, 0.2);
    transition: all 0.4s ease;
    padding: 1rem 1.5rem;
}

#small-search {
    display: inline-block;
    vertical-align: middle;

    input {
        height: 60px;
        border: 2px solid $secondary;
        width: 0%;
        padding: 0;
        transition: .3s all ease;
        position: absolute;
        right: 110px;
    }

    &:hover, &.open {
        input {
            width: 500px;
            padding: .375rem .75rem;
            transition: .3s all ease;
        }
    }
}

#search-btn, #basket-btn {
    height: 60px;    
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: .1rem;
    width: 110px;
}
#search-btn {
    padding: 14px 10px;
    font-weight: 600;

    img {
        width: 25px;
        vertical-align: bottom;
    }
}
#basket-btn {
    padding: 8px 25px;
    img{
        width:46px;
    }
}

#basket-amount {
    position: absolute;
    top: 4px;
    right: 23px;
    background: #545454;
    width: 22px;
    height: 22px;
    line-height: 22px;
    border-radius: 50%;
    font-size: .8rem;
    font-weight: 800;
}

@include media-breakpoint-down(lg) {
    #topbanner {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        font-size: .9rem;
        white-space: nowrap;
        padding: 8px 0;

        .container {
            overflow: hidden;
            text-overflow: ellipsis;
            padding: 0 10px 0 50px;
        }

        &.show {
            height: auto;
            white-space: normal;
        }
    }
}

@include media-breakpoint-down(md) {
    header {
        height: 60px;
        box-shadow: 0 0 5px rgba(0,0,0,.2);

        .container {
            max-width: 100%;
        }
    }

    #logo {
        margin: 5px auto;

        img {
            width: 160px;
            height: 50px;
        }
    }

    #top {
        border: none;
        padding: 0;
    }

    #basket-btn {
        margin-top: 0;
        height: 60px;
        padding: 8px;
        position: absolute;
        top: 0;
        right: 0;
        width: auto;

        img {
            width: 45px;
        }

        #basket-amount {
            top: 3px;
            right: 6px;
        }
    }

    #small-search {
        position: fixed;
        bottom: 0;
        left: -100%;
        right: 0;
        width: 100vw;
        display: block;
        z-index: 11;
        transition: .4s all ease;

        input {
            position: relative;
            width: calc(100% - 70px);
            top: 0;
            right: 0;
            padding: 15px;
        }

        &.open {
            left: 0;
            transition: .4s all ease;
        }
    }

    #search-btn {
        position: absolute;
        top: 0;
        right: 0;
        margin-top: 0;
        width: 70px;

        span {
            display: none;
        }
    }
}
