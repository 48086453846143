// Responsive iframes
iframe {
    max-width: 100%;
    border: none;
}

// WinPhone fix
@-ms-viewport {
    width: auto;
}
