﻿header.checkout-header {
    box-shadow: 0 0 10px rgba(0,0,0,.1);

    #logo {
        img {
            max-height: 80px;
            max-width: 460px;
        }
    }
}

main.checkout {
    padding-top: 111px;
}

.existing-attendees {
    background:rgba(192,206,203,0.3);
}

#checkout, #order-confirmation {
    background-color: #f1f1f1;
    padding: 40px 0;

    .section-wrapper {
        background: #fff;
        padding: 20px;

        .section-header {
            font-weight: 700;
            font-size: 1.3rem;
            margin-bottom: 20px;
        }

        a.edit {
            padding-right: 10px;
            border-right: 1px solid #cecece;
            margin-right: 10px;
        }
    }

    .order-overview {
        background: #fff;
        padding: 20px;
        position: fixed;
        max-width: 350px;
        width: 100%;
    }
}

#order-confirmation {
    h1{
        font-weight:600;
        i{
            color:$secondary;
        }
    }
}

footer.checkout-footer {
    height: auto;
    min-height: initial;

    .footer-content {
        position: relative;
        background: #20201e;
        padding: 50px 0;
    }
}

@include media-breakpoint-down(md) {
    main.checkout {
        padding-top: 60px;
    }
    header.checkout-header{
        #logo{
            img{
                margin:0;
            }
        }
    }
}
