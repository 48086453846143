﻿#menu {
    background-color: $light-green;
    font-weight: 800;
    text-align:center;

    .list-inline, .list-inline-item {
        margin: 0px;

        li {
            > a {
                display: block;
                padding: 10px 40px;
                color: #20201e;
                position: relative;
                font-size: 1rem;
                &:hover{
                    text-decoration:none;
                    background:rgba(0,0,0,.1);
                }
            }
        }
    }
}

.menu-preview {
    .icon {
        img {
            max-height: 80px;
            margin: 40px 0px;
        }
    }
}

#nav-toggle {
    position: absolute;
    width: 28px;
    height: 28px;
    cursor: pointer;
    z-index: 20;
    top: 20px;
    left: 15px;

    span {
        display: block;
        position: absolute;
        height: 3px;
        width: 50%;
        background: #111;
        opacity: 1;
        left: 0;
        transform: rotate(0deg);
        transition: .3s all;

        &:nth-child(even) {
            left: 50%;
        }

        &:nth-child(odd) {
            left: 0px;
        }

        &:nth-child(1), &:nth-child(2) {
            top: 0px;
        }

        &:nth-child(3), &:nth-child(4) {
            top: 9px;
        }

        &:nth-child(5), &:nth-child(6) {
            top: 18px;
        }
    }

    &.invert {
        span {
            background: $body-color;
        }
    }

    &.open {
        left: calc(100vw - 40px);
        transition: all 0.5s ease;

        span {
            &:nth-child(1), &:nth-child(6) {
                transform: rotate(45deg);
            }

            &:nth-child(2), &:nth-child(5) {
                transform: rotate(-45deg);
            }

            &:nth-child(1) {
                left: 0px;
                top: 2px;
            }

            &:nth-child(2) {
                left: 10px;
                top: 2px;
            }

            &:nth-child(3) {
                left: -50%;
                opacity: 0;
            }

            &:nth-child(4) {
                left: 100%;
                opacity: 0;
            }

            &:nth-child(5) {
                left: 0px;
                top: 12px;
            }

            &:nth-child(6) {
                left: 10px;
                top: 12px;
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .fixed {
        #menu {
            a {
                color: $body-color;
                transition: all 0.4s ease;
            }

            i {
                color: $body-color;
                transition: all 0.4s ease;
            }

            .list-inline-item {
                &.has-submenu {

                    .submenu {
                        box-shadow: 0px 2px 5px 0px rgba(0,0,0,.13);
                    }
                }
            }
        }
    }

    #menu {
        ul.list-inline {
            position: relative;
        }

        .list-inline-item {
            &.has-submenu {
                .submenu {
                    position: absolute;
                    top: 44px;
                    right: 0;
                    left: 0;
                    background: #ecf0ef;
                    padding: 30px;
                    font-weight: 400;
                    text-align: left;

                    a {
                        color: $body-color;
                        margin-bottom: 10px;
                        display: block;
                        line-height: 1.45;
                    }

                    span.menu-header {
                        font-weight: 800;
                        font-size: 1.5rem;
                        margin-bottom: 15px;
                        display: block;
                    }

                    .menu-preview {
                        .icon {
                            margin-bottom: 15px;
                        }

                        .preview-header {
                            font-weight: 800;
                        }
                    }

                    .subsubmenu-item {
                        font-size: .9rem;
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(lg) {
    #menu {
        .list-inline, .list-inline-item {
            li {
                > a {
                    padding: 10px 25px;
                }
            }
        }
    }
}

@include media-breakpoint-down(md) {
    #menu {
        background: #fff;
        position: fixed;
        top: 0px;
        left: -100vw;
        width: 100%;
        height: 100vh;
        z-index: 10;
        transition: all 0.4s ease;

        &::after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            background: $light-green;
            width: 100%;
            height: 60px;
            z-index: 10;
        }

        .container {
            padding: 0;
        }

        .list-inline {
            width: 100%;
            margin: auto;
            padding-top: 60px;
            bottom: 50px;
            left: 0px;
            right: 0px;
            overflow-y: scroll;

            div[class^="col-"] {
                padding: 0;
            }

            li {
                border-bottom: 1px solid #ddd;

                > a {
                    padding: 15px;
                    float: none;
                    display: block;

                    i {
                        float: right;
                        font-size: 1.3rem;
                    }
                }

                &.has-submenu {
                    .submenu {
                        position: absolute;
                        top: 60px;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background: #ecf0ef;
                        z-index: 11;
                        overflow-y: scroll;
                        padding: 0px 15px 60px 15px;
                        font-weight: 400;

                        a {
                            color: #333;
                            border-bottom: 1px solid #ddd;
                            display: block;
                            padding: 15px 30px;
                        }

                        span.menu-header {
                            font-weight: 800;
                            position: fixed;
                            top: 20px;
                            left: 0;
                            right: 0;
                            width: 100%;
                            text-align: center;
                        }

                        .close-subnav {
                            position: fixed;
                            top: 11px;
                            left: 20px;
                            font-size: 1.6rem;
                            font-weight: 700;
                            text-transform: uppercase;
                            z-index: 19;
                        }

                        .menu-preview {
                            display: none;
                        }

                        .submenu-section {
                            border-bottom: 10px solid #f1f1f1;
                        }
                    }

                    .course-submenu {
                        padding-bottom: 120px;

                        strong {
                            font-weight: 400;
                        }
                    }
                }
            }

            li:first-of-type {
                > a {
                    padding-left: 15px;
                }
            }
        }

        .list-inline-item {
            text-align: left;
            display: block;
            clear: both;
        }

        &.open {
            left: 0px;
            transition: all 0.4s ease;
        }
    }
}
