﻿.newsitem {
    position: relative;
    margin-bottom: 30px;
    box-shadow: 0px 0px 25px rgba(0,0,0,0.3);

    .content {
        position: absolute;
        bottom: 30px;
        left: 30px;
        margin-right:30px;
        background: #1d1d1b;
        padding: 10px;
        display: inline-block;
        max-width:100%;

        a {
            color: #fff;
            text-decoration: none;
        }
    }

    h2 {
        font-size: 1.15rem;
    }

    .date {
        font-weight: 600;
        font-size: 0.875rem;
    }

    .intro {
        font-size: 0.875rem;
    }
}
