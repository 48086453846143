﻿#course-categories {
    overflow: hidden;

    .category {
        margin-bottom: 30px;
        box-shadow: 0px 0px 25px rgba(0,0,0,0.3);
        padding:15px;

        img {
            max-height:80px;
            margin:30px 0px;
        }

        h3 {
            padding: 15px 0px;
            background: #1d1d1b;
            color: #fff;
            font-weight: 800;
            font-size: 0.85rem;
            text-align:center;
            margin:0px;
        }

        a {
            color: #fff;
            text-decoration: none;
        }
    }
}

#search {
    input {
        width: calc(100% - 86px);
        display: inline-block;
        font-size: 2rem;
        border: 2px solid $secondary;
        height: 80px;
    }

    .btn {
        padding: 15px 25px;
        display: inline-block;
        float: right;
        height: 80px;
    }
}

#focus-pages {
    overflow: hidden;

    .focuspage {
        position: relative;
        margin-bottom: 30px;
        box-shadow: 0px 0px 25px rgba(0,0,0,0.3);

        .content {
            display: inline-block;
            position: absolute;
            bottom: 30px;
            left: 30px;

            h3 {
                padding: 15px;
                background: #1d1d1b;
                color: #fff;
                font-weight: 800;
                font-size: 1.15rem;
                text-align: center;
                margin: 0px;
            }

            a {
                color: #fff;
                text-decoration: none;
            }
        }
    }
}

#page-intro {
    background-image: url(/dist/img/logo-grey.svg);
    background-repeat: no-repeat;
    background-position-x: right;
    background-size: 34%;
}

.manchet {
    font-size: 1.4rem;
}

.pre-footer {
    h2 {
        font-weight: 800;
        line-height: 1.1;
        margin-bottom: 20px;
    }

    p {
        margin-bottom: 25px;
    }

    .btn {
        height: 50px;
    }

    #program-box {
        color: #fff;

        .btn {
            margin-right: 15px;
        }

        img {
            box-shadow: 5px 5px 15px rgba(0,0,0,.4);
        }
    }

    #newsletter-box {
        #fieldEmail {
            max-width: 60%;
            height: 50px;
        }
    }
}

@include media-breakpoint-down(md) {
    #course-categories {
        .category {
            margin-bottom: 20px;
            box-shadow: 0px 0px 10px rgba(0,0,0,0.3);

            .content {
                h3 {
                    font-size: 1rem;
                    line-height: 1.7;
                }
            }
        }
    }

    #search {
        input {
            width: calc(100% - 60px);
            font-size: 1.2rem;
            height: 60px;
        }

        .btn {
            padding: 10px 12px;
            height: 60px;
        }
    }

    #focus-pages {
        .focuspage {
            margin-bottom: 20px;
            box-shadow: 0px 0px 10px rgba(0,0,0,0.3);
        }
    }

    #page-intro {

        .manchet {
            font-size: 1.2rem;
        }
    }
}
