﻿#basket-small {
    position: fixed;
    z-index: 2000;
    color: #333;
    right: -110%;
    top: 0px;
    width: 100%;
    max-width: 500px;
    height: 100%;
    background: #fff;
    padding: 15px;
    transition: right .3s;
    box-shadow: 0 0 15px rgba(0,0,0,.2);
    overflow-y: auto;

    &.open {
        right: 0;
        transition: right .3s;
    }

    a {
        color: #333;
    }

    .basket-header {
        border-bottom: 1px solid #ddd;
        padding-bottom: 15px;
        font-size: 2rem;
        margin-bottom: 5px;
        font-weight: 700;

        #basket-close {
            background: none;
            border: none;
            font-size: 1.2rem;
            color: #333;
        }
    }

    #basket-items-measure {
        position: absolute;
        top: 150px;
        bottom: 170px;
        z-index: -1;
    }

    .items {
        font-size: 1rem;

        .item {
            width: 100%;
            overflow: auto;
            padding: 10px 0px;
            border-bottom: 1px solid #ddd;

            div {
                float: left;
            }

            .remove {
                width: 20px;
            }

            .name {
                max-width: 92%;

                a {
                    display: block;
                }

                small {
                    display: block;
                }
            }

            .total {
                float: right;
            }
        }
    }

    .footer {
        padding-top: 15px;
        font-size: .9rem;
        line-height: 1.5;
        text-align: right;

        .total {
            display: block;
            font-size: 1.2rem;
            font-weight: bold;
            margin: 10px 0px 0px;
        }

        .vat {
            margin-bottom: 15px;
        }

        .btn {
            margin-top: 20px;
            font-size: 1.2rem;
            color: #fff;
        }
    }
}
