﻿$font-family-base: 'Montserrat', sans-serif;
$headings-font-family: 'Montserrat', sans-serif;

$primary:       #d57235;
$secondary:     #567974;
$light-green:   #c0cecb;
$grey:          #9d9d9c;
$black:         #1d1d1b;
$light-blue:    #dfe6ec;

$light-background: #f7f7f7;

$body-color: #1c2129;

$h1-font-size: 3rem;
$h2-font-size: 2.5rem;

//$headings-font-weight: 400;

//$lead-font-size:1.5rem;

$link-color: $secondary;

//$input-box-shadow: none;

$border-radius: 0;
//$border-radius-lg: 0px;
//$border-radius-sm: 0px;

$grid-breakpoints: ( xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1400px, xxl: 1600px );

$container-max-widths: ( sm: 540px, md: 720px, lg: 960px, xl: 1140px, xxl: 1400px );
