﻿.basket-icon {
    display: block;
    width: 250px;
    filter: invert(48%) sepia(17%) saturate(244%) hue-rotate(167deg) brightness(89%) contrast(84%);
}

.basket-form {
    .section-header {
        font-weight: bold;
    }
}

#discount {
    input {
        height: 50px;
    }
}

@include media-breakpoint-up(lg) {
    .basket-navigation {
        display: flex;

        .btn-forward {
            order: 2;
        }

        .btn-back {
            order: 1;
        }

        button.btn {
            margin: 0;
        }
    }
}
