﻿.employees {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 30px;

    .employee {
        small {
            font-size: 90%;
        }
    }
}

@include media-breakpoint-down(xl) {
    .employees {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@include media-breakpoint-down(md) {
    .employees {
        grid-template-columns: 1fr 1fr;
    }
}

@include media-breakpoint-down(xs) {
    .employees {
        display: block;

        .employee {
            margin-bottom:40px;
        }
    }
}
