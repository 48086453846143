﻿.bg-light-green{
    background-color:$light-green;
}

.bg-grey {
    background-color: $grey;
}

.grid{
    > .row{
        margin-bottom:50px;
    }
}

@include media-breakpoint-down(md) {
    .section {
        padding: 100px 0px;
    }
}
