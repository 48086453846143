﻿.frontpage {
    footer {
        min-height: 700px;
    }
}

footer {
    position: relative;
    min-height: 250px;
    overflow: hidden;
    color: #fff;
    line-height: 2;

    a {
        color: #fff;

        &:hover {
            color: #fff;
        }
    }

    .footer-image {
        position: absolute;
    }

    span.footer-header {
        font-weight: 800;
    }

    .footer-content {
        padding: 180px 0 50px 0;
        position: absolute;
        width: 100%;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgb(46,46,46);
        background: -moz-linear-gradient(0deg, rgba(46,46,46,1) 0%, rgba(255,255,255,0) 100%);
        background: -webkit-linear-gradient(0deg, rgba(46,46,46,1) 0%, rgba(255,255,255,0) 100%);
        background: linear-gradient(0deg, rgba(46,46,46,1) 0%, rgba(255,255,255,0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#2e2e2e",endColorstr="#ffffff",GradientType=1);
    }

    .payment-logos {
        margin-top: 20px;

        img {
            margin-right: -2px;
        }
    }
}

@include media-breakpoint-down(md) {
    footer{
        min-height:600px;
        .footer-content{
            padding:30px 15px;
        }
    }
}
