﻿/* Umbraco Forms */
.umbraco-forms-form {
    .umbraco-forms-fieldset {
        > legend {
            padding-bottom: 10px;
        }

        .umbraco-forms-page {
            margin-bottom: 15px;
        }

        .umbraco-forms-field {

            .umbraco-forms-field-wrapper {

                .form-check {
                    .form-check-label {
                        cursor: pointer;
                    }
                }
            }

            textarea {
                height: 200px;
            }
        }
    }
}

.input-validation-error {
    color: $danger;
    border-color: $danger;

    &:focus {
        border-color: $danger;
        box-shadow: 0 0 0 0.2rem rgba(220,53,69,.25);
    }
}

.field-validation-error {
    width:100%;
    display: inline-block;
    background: #f8d7da;
    border: 1px solid #f5c6cb;
    color: $danger;
    padding:10px;
}
