﻿#enrollment-statistics {
    th {
        white-space: nowrap;
    }

    th a.sort-by {
        padding-right: 18px;
        position: relative;
    }

    a.sort-by:before,
    a.sort-by:after {
        border: 4px solid transparent;
        content: "";
        display: block;
        height: 0;
        right: 5px;
        top: 50%;
        position: absolute;
        width: 0;
    }

    a.sort-by:before {
        border-bottom-color: #ccc;
        margin-top: -9px;
    }

    a.sort-by:after {
        border-top-color: #ccc;
        margin-top: 1px;
    }

    a.sort-by.active:not(.desc):before {
        border-bottom-color: #333;
    }

    a.sort-by.active.desc:after {
        border-top-color: #333;
    }
}
