﻿#topicon {
    padding: 40px;

    img {
        max-height: 130px;
        margin-right:100px;
    }

    h1 {
        font-size: 1.4rem;
        background: #1d1d1b;
        color: #fff;
        padding: 10px;
    }
}

#courselist {
    .col {
        margin-bottom: 30px;
    }

    .card {
        position: relative;
        box-shadow: 0px 0px 25px rgba(0,0,0,0.4);
        color: $body-color;

        .card-title {
            position: absolute;
            left: 1rem;
            bottom: 1rem;
            display: inline-block;
            padding: 1rem;
            background: #1d1d1b;
            color: #fff;

            h3 {
                font-size: 1rem;
                font-weight: 800;
                margin-bottom: 0px;
            }

            p {
                margin-bottom: 0px;
            }
        }

        .card-body {
            font-size: 0.875rem;

            ul {

                .property-name {
                    font-weight: 600;
                }
            }
        }

        &:hover {
            text-decoration: none;
        }
    }

    .stock-label {
        top: 20px;
        right: 0px;
        font-size: 0.75rem;
        font-weight: 600;
    }

    .pagination {
        font-weight: 600;
        line-height: 34px;

        img {
            height: 34px;
            margin: 0px 15px;

            &.prev {
                transform: rotate(180deg);
            }

            &.disabled {
                opacity: 0.2;
            }
        }
    }

    .content {
        max-width: 900px;
        margin: auto;

        a {
            text-decoration: underline;
        }
    }

    .filters {
        .dropdown {
            position: relative;

            .btn {
                line-height: 34px;

                &:after {
                    content: none;
                }

                .badge {
                    background: rgba(0,0,0,0.3);
                    border-radius: 3px;
                }

                .down {
                    height: 34px;
                    transform: rotate(90deg);
                    filter: invert(1);
                    float: right;
                }
            }

            input {
                margin-right: 0.5rem;
            }

            label {
                width: 100%;
                font-size: 0.875rem;
                margin-bottom: 0.2rem;
            }

            .dropdown-menu {
                margin: 0px;
                border: none;
                box-shadow: 0px 0px 10px rgba(0,0,0,0.5);

                li {
                    white-space: nowrap;
                }
            }
        }

        #filter-search {
            padding: 15px 25px;
        }
    }
}

@include media-breakpoint-down(md) {
    #topicon {
        padding: 20px;

        img {
            max-height: 60px;
            margin-right: 50px;
        }

        h1 {
            font-size: 1rem;
        }
    }
}

@include media-breakpoint-down(sm) {
    #topicon {
        img {
            max-height: 35px;
            margin-right: 20px;
        }

        h1 {
            font-size: 0.8rem;
            padding:5px;
        }
    }
}
