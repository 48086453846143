﻿figcaption {
    font-size: 0.875rem;
    color: #777;
}

.small-text {
    font-size: 0.875rem;
}

@include media-breakpoint-down(md) {
    h1, .h1 {
        font-size: 2rem;
    }

    h2, .h2 {
        font-size: 2rem;
    }
    h3, .h3{
        font-size:1.3rem;
    }
}
