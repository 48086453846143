﻿.course-page {
    background: $light-blue;

    .course-details {
        background: rgba(255,255,255,0.5);
        padding: 20px;

        .h3 {
            font-weight: 800;
            text-transform: uppercase;
            color: #759d97;
        }

        strong {
            font-weight: 600;
        }

        .large {
            font-size: 1.15rem;
        }

        .stock-label {
            top: 20px;
            right: 15px;
        }

        #add-to-cart {
            input {
                width: 60px;
                height: 60px;
                display: inline-block;
                border: 2px solid #111;
                font-weight: 600;
            }

            .btn {
                text-transform: uppercase;
                padding: 15px 40px;
                height: 60px;
                vertical-align: bottom;
            }
        }
    }

    .category {
        display: inline-block;
        padding: 15px;
        background: #1d1d1b;
        color: #fff;
        font-weight: 800;
        line-height: 1.6;
    }

    h1 {
        font-weight: 800;
        font-size: 3rem;
        line-height: 1.2;

        .subtitle {
            font-weight: 600;
            font-size: 2.5rem;
        }
    }

    .teacherprofile {
        img {
            margin-bottom: 20px;
        }

        a {
            color: #111;
            text-decoration: underline;
        }
    }

    #lessons {
        padding: 20px;
        background: rgba(0,0,0,0.1);
    }

    #toggle-lessons {
        background: none;
        border: none;
        padding: 0px;
        color: $secondary;
        text-decoration: underline;
    }

    #otherlessons {
        background: rgba(0,0,0,0.05);

        .header {
            font-size: 1.25rem;
            font-weight: 600;
            margin: initial;
        }

        .title {
            font-weight: 600;
        }
    }

    #back-in-stock {
        background: rgba(255,255,255,0.75);
        font-family: $font-family-base !important;

        p {
            font-family: $font-family-base !important;
        }

        .h3 {
            font-size: 1.25rem;
        }

        button {
            text-transform: uppercase;
            padding: 15px 25px;
        }

        input[type=email] {
            height: 60px;
            border: 2px solid #111;
            font-weight: 600;
        }
    }
}

@include media-breakpoint-down(md) {
    .course-page {
        h1 {
            font-size: 2.2rem;

            .subtitle {
                font-size: 1.7rem;
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    .course-page {
        h1 {
            font-size: 1.7rem;

            .subtitle {
                font-size: 1.3rem;
            }
        }

        .teacherprofile {
            margin-bottom: 20px;

            img {
                margin-bottom: 10px;
            }
        }
    }
}
